// export const basePath = "https://api.grupovivi.com/";
export const basePath = "https://api-qa.grupovivi.com/";
//export const basePath = "https://vivi-back-lb-1590340918.us-east-1.elb.amazonaws.com/api";
// export const basePath = "http://localhost:3200/";

export const apiVersion = "v2";
// export const API_URL = "https://vivi.sinaptic.com.ar/";
//  export const API_URL = "https://api.grupovivi.com/api/";
export const API_URL = "https://api-qa.grupovivi.com/api/";
// export const API_URL = "https://ec2-18-207-180-137.compute-1.amazonaws.com/api/";
// export const API_URL = "http://localhost:3200/api/";
export const STRIPE_KEY =
  "pk_test_51OB09HEfZFXAavZ1kaZBTWaVirCIpxfw6BRaWIbIGOIlI4hGggluEGYgZHiU1iXOXuHSLNs4C8FEwhaabDzy3OXJ00iXIf1sS3";
export const RETURN_URL_STRIPE = "https://qa.grupovivi.com/success";
