import React, { useEffect } from "react";

import Appointments from "../../components/appointments/index";
import { useLocation, useHistory } from "react-router-dom";
import { getAccessTokenApi } from "../../api/auth";
import { RetrieveOrderBancamiga } from "../../api/bancamiga";
import { toast, ToastContainer } from "react-toastify";
import { useState } from "react";
import Loader from "../../components/Loader/Loader";

const AppointmentsPage = () => {
  const location = useLocation();
  const objeto = location?.objeto;
  const token = getAccessTokenApi();
  const history = useHistory();
  const [pagoMovil, setPagoMovil] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const searchParams = new URLSearchParams(location.search.substring(1));
    const orderId = searchParams.get("OrderID");

    console.log(orderId);
    if (orderId !== undefined && orderId !== null) {
      RetrieveOrderBancamiga(token, orderId).then((data) => {
        if (data.data.Status === "approved") {
          setPagoMovil(true);
          toast.success(
            "¡Felicidades! Tu pago ha sido procesado con éxito. Gracias por elegir nuestros servicios. Tu transacción se ha completado satisfactoriamente.",
            {
              position: "top-center",
              autoClose: 2800,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );

          if (data.appointment?.emergency) {
            setLoading(false);

            history.push({
              pathname: "/waitingRoom",
              objeto: {
                isSchedule: !data.appointment?.emergency,
                appointmentId: data.appointment?._id,
                isAppointment: false,
                appointment: data.appointment,
              },
            });
          }
        } else {
          toast.error(
            "Tu pago no pudo ser procesado. Inténtalo nuevamente gracias",
            {
              position: "top-center",
              autoClose: 2800,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      });
    } else {
      if (objeto?.appointment.emergency && !objeto?.acceptDoctor) {
        setLoading(false);

        history.push({
          pathname: "/waitingRoom",
          objeto: {
            isSchedule: !objeto.appointment?.emergency,
            appointmentId: objeto.appointment?._id,
            isAppointment: false,
            appointment: objeto.appointment,
          },
        });
      }
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Appointments dataEmergency={objeto} pagoMovil={pagoMovil} />
      <ToastContainer />
    </>
  );
};

export default AppointmentsPage;
