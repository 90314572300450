import React, { useState, useEffect } from "react";
import { View, Text } from "react-native";

import { toast, ToastContainer } from "react-toastify";
import moment from "moment-timezone";

import { Button, Row, Col, Divider, Input, Form } from "antd";
import {
  UserOutlined,
  CreditCardOutlined,
  CalendarOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import { getAccessTokenApi } from "../../api/auth";
import "./confirmappointment.css";
import PagoMovil from "../../assets/phone.png";
import { useHistory } from "react-router-dom";
import "react-credit-cards/es/styles-compiled.css";

import { CreateOrderBancamiga, cargoBolivares } from "../../api/bancamiga";
import axios from "axios";
import { API_URL, apiVersion } from "../../api/config";
import Cards from "react-credit-cards";
import { getUserApi } from "../../api/user";
import { updateUserSocket } from "../../services/socket";

const ConfirmAppointmentModal = (props) => {
  const navigate = useHistory();
  const token = getAccessTokenApi();
  const [paymentMethodView, setPaymentMethodView] = useState("bancamiga");
  // eslint-disable-next-line no-unused-vars
  const [price, setPrice] = useState(props.price);
  // eslint-disable-next-line no-unused-vars
  const [zelle, setZelle] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [user, setUser] = useState(null);

  // const stripe = useStripe();
  // const elements = useElements();
  // eslint-disable-next-line no-unused-vars
  const [message, setMessage] = useState(null);
  const [dniBancamiga, setDNIBancamiga] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [descriptionBancamiga, setDescriptionBancamiga] = useState(null);
  const [cardNumber, setCardNumber] = useState("");
  const [cardCedula, setCardCedula] = useState("");
  const [cardValid, setCardValid] = useState("");
  const [cvv, setCvv] = useState("");
  const [focus, setFocus] = useState("name");
  const [orderUrl, setOrderUrl] = useState(null);
  const [counter, setCounter] = useState(0);

  const { appointmentId } = props;

  useEffect(() => {
    const userID = localStorage.getItem("USER_ID");
    updateUserSocket(userID);

    if (userID !== null) {
      getUserApi(token, userID).then((response) => {
        setUser(response.user);
        setDNIBancamiga(
          response.user.documentNumber === undefined
            ? ""
            : response.user.documentNumber
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const focusCard = async (data) => {
    let inputValue = data;

    inputValue = inputValue.replace(/[^0-9]/g, "");
    inputValue = inputValue.slice(0, 4);

    const regex = /^\d{1,4}$/;
    if (regex.test(inputValue) || inputValue === "") {
      setCvv(inputValue);
      setFocus("cvc");
    }
  };

  const setExpiry = async (data) => {
    console.log(data);
    setCardValid(data);
    setFocus("name");
  };

  const clickPayment = async () => {
    setIsLoading(true);
    const splitCVC = cardValid.split("/");

    const bodyObj = {
      numero: cardNumber,
      mes: splitCVC[0],
      ano: splitCVC[1],
      monto: price.toString(),
      cvc: cvv,
      cedula: cardCedula,
      referencia: "",
    };

    cargoBolivares(token, bodyObj)
      .then((data) => {
        if (data?.ok && data?.respuesta_codigo === "00" && data?.aprobado) {
          let pagomovil = {
            Created: new Date().toString(),
            Amount: price.toString(),
            Phoneorig: "",
            Banco: "card",
            Type: "Tarjeta de crédito",
            Nroref: data?.referencia,
          };

          let url = `${API_URL}${apiVersion}/update-appointment/${appointmentId}`;

          axios
            .put(
              url,
              {
                status: "acepted",
                transaction: JSON.stringify(pagomovil),
              },
              {
                headers: {
                  Authorization: token,
                },
              }
            )
            .then((r) => {
              if (r?.data) {
                setIsLoading(false);
                setTimeout(
                  () =>
                    navigate.push({
                      pathname: "/appointments",
                      objeto: {
                        appointment: r.data.appointment,
                        patient: user,
                      },
                    }),
                  2000
                );
              }
            });
        } else {
          toast.error(
            data === undefined
              ? "Hubo un error al procesar el pago."
              : data?.respuesta_data === ""
              ? data?.mensaje
              : data?.mensajeCod,
            {
              position: "top-center",
              autoClose: 2800,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const openDialog = function (uri, name, options) {
    var win = window.open(uri, name, options);
    var interval = window.setInterval(function () {
      try {
        if (win == null || win.closed) {
          window.clearInterval(interval);
          handlePaymentWindowClose();
        }
      } catch (e) {}
    }, 1000);
    return win;
  };

  const clickPaymentBancamiga = () => {
    // Evitar múltiples clics mientras se procesa el pago
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    const bodyObj = {
      Monto: price.toString(),
      Motivo: descriptionBancamiga,
      FullName: user.fullName,
      DNI: dniBancamiga,
      Appointment_Id: appointmentId,
    };

    CreateOrderBancamiga(token, bodyObj)
      .then((data) => {
        console.log("data banca amiga", data);
        if (data.data?.Status === "pending") {
          // Almacena la URL de la orden
          setOrderUrl(data.data.url);

          // Abre la ventana de pago
          const paymentWindow = openDialog(
            data.data.url,
            "BancamigaPayment",
            "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=600,height=800"
          );

          // Establece un intervalo para verificar si la ventana se ha cerrado
          const interval = window.setInterval(() => {
            try {
              if (paymentWindow == null || paymentWindow.closed) {
                window.clearInterval(interval);
                handlePaymentWindowClose();
              }
            } catch (e) {}
          }, 1000);
        } else {
          // Manejar errores de pago
          toast.error("Hubo un error al procesar el pago.");
          setIsLoading(false); // Asegúrate de actualizar el estado en caso de error
        }
      })
      .catch((error) => {
        // Manejar errores de red u otros errores
        console.error("Error al procesar el pago:", error);
        toast.error("Hubo un error al procesar el pago.");
        setIsLoading(false); // Asegúrate de actualizar el estado en caso de error
      });
  };

  const handlePaymentWindowClose = () => {
    // La ventana emergente se cerró
    console.log("La ventana emergente se cerró");

    setIsLoading(false);
  };

  // Función para verificar el tiempo de expiración
  const checkExpiration = () => {
    if (orderUrl && counter >= 590) {
      // Si el contador llega a 590 (9 minutos y 50 segundos), reiniciar la orden
      setCounter(0);
      setOrderUrl(null);
    } else {
      // Incrementar el contador si la URL de la orden está almacenada
      if (orderUrl) {
        setCounter((prevCounter) => prevCounter + 1);
      }
    }
  };

  // Establece un intervalo para verificar el tiempo de expiración
  useEffect(() => {
    const intervalId = setInterval(checkExpiration, 1000);

    // Limpia el intervalo cuando el componente se desmonta
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderUrl, counter]);

  // Maneja el clic del botón de pago
  const handlePaymentButtonClick = () => {
    console.log("counter", counter);
    if (orderUrl && counter < 590) {
      // Si la URL de la orden está almacenada y no ha pasado más de 10 minutos, abrir la misma URL
      const paymentWindow = window.open(
        orderUrl,
        "BancamigaPayment",
        "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=600,height=800"
      );

      // Establece un intervalo para verificar si la ventana se ha cerrado
      const interval = window.setInterval(() => {
        try {
          if (paymentWindow == null || paymentWindow.closed) {
            window.clearInterval(interval);
            handlePaymentWindowClose();
          }
        } catch (e) {}
      }, 1000);
    } else {
      // Si no hay URL de orden almacenada o ha pasado más de 10 minutos, iniciar el proceso de pago nuevamente
      console.log("se inicio el pago de cero");
      clickPaymentBancamiga();
    }
  };

  return (
    <>
      <Row
        gutter={24}
        className="row-without-margin"
        style={{ height: "100vh" }}
      >
        <Col xxl={1} xl={1} xs={0} style={{ backgroundColor: "#f8f8fa" }}></Col>
        <Col xxl={19} xl={17} xs={24} style={{ backgroundColor: "#f8f8fa" }}>
          <div>
            <span className="span-text-title title-confirm-appointment">
              Pago de consulta
            </span>

            <div className="box-report-appointment">
              <div
                style={{
                  display: "flex",
                }}
              >
                <span
                  style={{
                    fontWeight: "500",
                    fontSize: "18px",
                    paddingBottom: 20,
                  }}
                >
                  Método de pago
                </span>
              </div>

              <Row gutter={24} style={{ textAlignLast: "justify" }}>
                {/* <Col xs={24} md={8} xl={6} type="flex" align="middle">
                  <Button
                    className="btn-metodo-pago"
                    onClick={() => {
                      setPaymentMethodView("stripe");
                    }}
                  >
                    <CreditCardOutlined style={{ fontSize: 20 }} />{" "}
                    <span>Tarjeta de Crédito</span>
                  </Button>
                </Col> */}

                <Col xs={24} md={8} xl={6} type="flex" align="middle">
                  <Button
                    className="btn-metodo-pago"
                    onClick={() => {
                      setPaymentMethodView("bancamiga");
                    }}
                  >
                    <img
                      src={PagoMovil}
                      alt={`logo VIVI`}
                      style={{
                        maxHeight: 35,
                        maxWidth: 35,
                      }}
                    />{" "}
                    <span>Tarjeta de crédito internacional</span>
                  </Button>
                </Col>
              </Row>

              <Divider />

              {paymentMethodView === "stripe" ? (
                <View
                  style={{
                    height: "100%",
                    display: "contents",
                  }}
                >
                  <form id="payment-form">
                    <Row gutter={24} className="payment-form-content">
                      <Col xl={24} xs={24}>
                        {/* <PaymentElement id="payment-element" options={paymentElementOptions} /> */}
                        <Row>
                          <Col xs={24} md={24} lg={12}>
                            <Form>
                              <Row gutter={24}>
                                <Col span={20}>
                                  <Form.Item style={{ width: "100%" }}>
                                    <label className="card-form__label">
                                      Número de tarjeta
                                    </label>
                                    <Input
                                      className="card-form__input"
                                      prefix={<CreditCardOutlined />}
                                      placeholder="0000 0000 0000 0000"
                                      type="text"
                                      value={cardNumber}
                                      maxLength={19}
                                      onChange={(e) => {
                                        let inputCreditCard = e.target.value
                                          .replace(/[^\d]/g, "")
                                          .replace(/(.{4})/g, "$1 ");

                                        inputCreditCard =
                                          inputCreditCard.length > 18
                                            ? inputCreditCard.substring(
                                                0,
                                                inputCreditCard.length - 1
                                              )
                                            : inputCreditCard;

                                        setCardNumber(inputCreditCard);
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>

                              <Row gutter={24}>
                                <Col span={10}>
                                  <Form.Item style={{ width: "100%" }}>
                                    <label className="card-form__label">
                                      Vencimiento
                                    </label>
                                    <Input
                                      className="card-form__input"
                                      prefix={<CalendarOutlined />}
                                      placeholder="MM / AA"
                                      value={cardValid}
                                      onChange={(e) => {
                                        const input = e.target.value;
                                        let formattedDate = input
                                          .replace(/\D/g, "") // Eliminar todos los caracteres que no sean dígitos
                                          .slice(0, 4) // Limitar la longitud máxima a 4 caracteres
                                          .replace(/(\d{2})(\d{2})/, "$1/$2");

                                        setExpiry(formattedDate);
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={10}>
                                  <Form.Item style={{ width: "100%" }}>
                                    <label className="card-form__label">
                                      CVV
                                    </label>
                                    <Input
                                      className="card-form__input"
                                      prefix={<UnlockOutlined />}
                                      placeholder="123"
                                      value={cvv}
                                      onChange={(e) =>
                                        focusCard(e.target.value)
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>

                              <Row gutter={24}>
                                <Col span={20}>
                                  <Form.Item style={{ width: "100%" }}>
                                    <label className="card-form__label">
                                      Cédula
                                    </label>
                                    <Input
                                      className="card-form__input"
                                      prefix={<UserOutlined />}
                                      placeholder="Ingrese su cédula de identidad"
                                      onChange={(e) =>
                                        setCardCedula(e.target.value)
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Form>
                          </Col>

                          <Col xs={24} md={24} lg={12} className="cardDiv">
                            <Cards
                              number={cardNumber}
                              expiry={cardValid}
                              cvc={cvv}
                              focused={focus}
                              checkCustomMaxLength="20"
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col xl={8} xs={0}></Col>
                      <Col xl={4} xs={0}></Col>
                      <Col
                        xl={12}
                        xs={24}
                        className="col-btn-payment"
                        style={{ textAlign: "right" }}
                      >
                        <Button
                          className="btn-prev-index"
                          onClick={() => navigate.goBack()}
                        >
                          Salir
                        </Button>
                        <Button
                          disabled={isLoading}
                          loading={isLoading}
                          className="btn-next-index"
                          style={{ borderStyle: "unset" }}
                          onClick={() => clickPayment()}
                        >
                          <span id="button-text">Pagar</span>
                        </Button>
                      </Col>
                    </Row>
                    {/* Show any error or success messages */}
                    {message && <div id="payment-message">{message}</div>}
                  </form>
                </View>
              ) : null}

              {paymentMethodView === "bancamiga" ? (
                <>
                  <Row gutter={24}>
                    <Col xl={8} xs={24}>
                      <Form>
                        <Row gutter={24}>
                          <Col span={24}>
                            <Form.Item style={{ width: "100%" }}>
                              <label className="card-form__label">C.I</label>
                              <Input
                                className="card-form__input"
                                value={dniBancamiga}
                                onChange={(e) => {
                                  setIsLoading(false);
                                  const value = e.target.value;
                                  const regex = /^[a-zA-Z0-9]{0,10}$/;
                                  if (regex.test(value) || value === "") {
                                    setDNIBancamiga(value);
                                  }
                                }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                    <Col xl={8} xs={0}></Col>
                  </Row>

                  <Row gutter={24} style={{ paddingTop: "2%" }}>
                    {/* <Col xl={8} xs={0}></Col> */}
                    <Col xl={4} xs={0}></Col>
                    <Col
                      xl={12}
                      xs={24}
                      className="col-btn-payment"
                      style={{ textAlign: "right" }}
                    >
                      <Button
                        className="btn-prev-index"
                        onClick={() => {
                          setOrderUrl(null);
                          setCounter(0);
                          navigate.goBack();
                        }}
                      >
                        Salir
                      </Button>
                      <Button
                        disabled={isLoading}
                        loading={isLoading}
                        onClick={() => handlePaymentButtonClick()}
                        className="btn-next-index"
                        style={{ borderStyle: "unset" }}
                      >
                        <span id="button-text">Pagar</span>
                      </Button>
                    </Col>
                  </Row>
                </>
              ) : null}
            </div>
          </div>
        </Col>
        <Col xxl={4} xl={6} xs={0} style={{ backgroundColor: "#fff" }}>
          <div
            className="mainContainer"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <div className="infoConsultaDiv">
              <span className="title-lateral">Información de la consulta</span>

              {/* <Divider></Divider> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: -1,
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
              >
                <Divider orientation="center" className="dividerClass" />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    width: "100%",
                    paddingLeft: 20,
                    paddingTop: 20,
                  }}
                >
                  <span className="title-lateral-light">Especialidad</span>
                  <Text className="title-lateral-bold">
                    {props.specialtyTitle || "-"}
                  </Text>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    width: "100%",
                    paddingLeft: 20,
                    paddingTop: 20,
                  }}
                >
                  <span className="title-lateral-light">
                    Profesional de la salud
                  </span>
                  <Text
                    className="title-lateral-bold"
                    style={{ textTransform: "capitalize" }}
                  >
                    {props.item?.doctor?.fullName}
                  </Text>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  width: "100%",
                  paddingLeft: 20,
                  paddingTop: 20,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    width: "100%",
                  }}
                >
                  <span className="title-lateral-light">Fecha</span>
                  <Text className="title-lateral-bold">
                    {props.selectedDate !== undefined
                      ? moment(props.selectedDate?.date).format("DD/MM/YYYY")
                      : "-"}
                  </Text>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    width: "100%",
                    paddingTop: 20,
                  }}
                >
                  <span className="title-lateral-light">Hora</span>
                  <Text className="title-lateral-bold">
                    {props.item?.time || "--:--"}
                  </Text>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  // width: "50%",
                  paddingLeft: 20,
                  paddingTop: 20,
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span className="title-lateral-light">
                    Motivo de la consulta
                  </span>
                  <Text className="title-lateral-bold">{props.reason}</Text>
                </div>
              </div>

              <Divider />

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  marginLeft: "3rem",
                }}
              >
                <Text
                  style={{
                    marginLeft: "-1.5rem",
                    fontWeight: 500,
                    fontSize: "1.2rem",
                    color: "#006AFF",
                    fontFamily: "Roboto",
                  }}
                >
                  {`Monto: $${price}`}
                </Text>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <ToastContainer />
    </>
  );
};

export default ConfirmAppointmentModal;
